import React from 'react';
import './sidebar.scss';
import logo from '../../../assets/img/logo/logo1.svg';
import DashboardIcon from '../../../assets/img/icon/dashboard.svg';
import UsersIcon from '../../../assets/img/icon/users.svg';
import DropdownIcon from '../../../assets/img/icon/dropdown.svg';
import ActivityIcon from '../../../assets/img/icon/activities.svg';
import RevenueIcon from '../../../assets/img/icon/revenue.svg';
import BookingIcon from '../../../assets/img/icon/booking.svg';
import PaymentsIcon from '../../../assets/img/icon/payments.svg';
import SubscriptionIcon from '../../../assets/img/icon/subscription.svg';
import DataIcon from '../../../assets/img/icon/data.svg';
import SettingsIcon from '../../../assets/img/icon/settings.svg';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../rootReducer';
import { Badge } from '@mui/material';
import { fetchPendingApprovalUsersCount } from './sidebar.action';
import { DashboardStatistics } from '../Dashboard/dashboard.reducer';

const UsersSubPaths = ['/users/gingrs', '/users/clients'];

export const Sidebar: React.FC = () => {
  const [userSubmenu, setUserSubmenu] = React.useState<boolean>(false);

  const [statisticsInfo, setStatisticsInfo] =
    React.useState<DashboardStatistics>({
      clientsPendingApproval: 0,
      escortsPendingApproval: 0,
      totalLiveClients: 0,
      totalLiveEscorts: 0,
      totalRegisteredClients: 0,
      totalRegisteredEscorts: 0,
      totalRegisteredToday: 0,
      totalRegisteredThisMonth: 0,
      totalRegisteredThisYear: 0,
      percentageToday: 0,
      percentageThisMonth: 0,
      percentageThisYear: 0,
    });

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const config = window.app_config || {};
  const statisticsData = useSelector(
    (state: AppState) => state.data.dashboard.statistics
  );
  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const handleUserDropDown = () => {
    setUserSubmenu(!userSubmenu);
  };

  React.useEffect(() => {
    (async () => {
      dispatch(fetchPendingApprovalUsersCount());
    })();
  }, []);

  React.useEffect(() => {
    const result = UsersSubPaths.filter((path) => pathname.includes(path));
    if (UsersSubPaths.includes(pathname) || result.length > 0) {
      setUserSubmenu(true);
    }
  }, [pathname]);

  React.useEffect(() => {
    if (statisticsData && statisticsData.information !== 'INCOMPLETE') {
      setStatisticsInfo(statisticsData.information);
    }
  }, [statisticsData]);

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <NavLink to="/">
          <img src={logo} alt="Logo" />
        </NavLink>
      </div>
      <ul className="sidebar-menu">
        <li>
          <NavLink
              to="/dashboard"
              className="sidebar-menu-item"
              activeClassName="active"
          >
            <DashboardIcon className="sidebar-menu-icon"/>
            <span className="sidebar-menu-label">Dashboard</span>
          </NavLink>
        </li>
        <li
            className={
              !userSubmenu ? 'sidebar-submenu-open' : 'sidebar-submenu-close'
            }
        >
          <button className="sidebar-menu-item" onClick={handleUserDropDown}>
            <UsersIcon className="sidebar-menu-icon"/>
            <span className="sidebar-menu-label">Users</span>
            <DropdownIcon className="sidebar-dropdown"/>
          </button>

          <ul className="sidebar-submenu">
            <li className="sidebar-subitem">
              <NavLink
                  to="/users/clients"
                  className="sidebar-menu-item"
                  activeClassName="active"
              >
                <Badge
                    badgeContent={statisticsInfo.clientsPendingApproval}
                    color="error"
                >
                  <span className="sidebar-menu-label">Clients</span>
                </Badge>
              </NavLink>
            </li>

            <li className="sidebar-subitem">
              <NavLink
                  to="/users/gingrs"
                  className="sidebar-menu-item"
                  activeClassName="active"
              >
                <Badge
                    badgeContent={statisticsInfo.escortsPendingApproval}
                    color="error"
                >
                  <span className="sidebar-menu-label">Gingrs</span>
                </Badge>
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
              to="/transactions"
              className="sidebar-menu-item"
              activeClassName="active"
          >
            <PaymentsIcon className="sidebar-menu-icon"/>
            <span className="sidebar-menu-label">Transactions</span>
          </NavLink>
        </li>
        <li>
          <NavLink
              to="/configuration"
              className="sidebar-menu-item"
              activeClassName="active"
          >
            <PaymentsIcon className="sidebar-menu-icon"/>
            <span className="sidebar-menu-label">Configuration</span>
          </NavLink>
        </li>
        <li>
          <NavLink
              to="/settings"
              className="sidebar-menu-item"
              activeClassName="active"
          >
            <SettingsIcon className="sidebar-menu-icon"/>
            <span className="sidebar-menu-label">Settings</span>
          </NavLink>
        </li>
        <li>
          <NavLink
              to="/content/countries"
              className="sidebar-menu-item"
              activeClassName="active"
          >
            <SettingsIcon className="sidebar-menu-icon"/>
            <span className="sidebar-menu-label">Content</span>
          </NavLink>
        </li>
        <li>
          <Link
              to={{
                pathname: config.api_3_url + '/hangfire?Access_token=' + token,
              }}
              target="_blank"
              className="sidebar-menu-item"
          >
            <DashboardIcon className="sidebar-menu-icon"/>
            <span className="sidebar-menu-label">Jobs Dashboard</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

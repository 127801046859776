import {GingrClient3} from "../clients/GingrClient3";
import {BaseService} from "./BaseService";

export class AdminConfigService {

    static async getConfigs(    token: string
    ): Promise<any> {
        return await GingrClient3.get(
            `admin/config`,
            BaseService.getBaseHeaders(token)
        );
    }

    static async updateConfig(token: string, property: string, value: string) {
        return await GingrClient3.put(
            `admin/config`,
            {key: property, value},
            BaseService.getBaseHeaders(token)
        );
}
}

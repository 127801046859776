import React, {useEffect, useState} from 'react';
import {Header} from '../../Elements/Header/Header';
import {AdminCreditBalanceService} from '../../../service/AdminCreditBalanceService';
import {useSelector} from 'react-redux';
import {AppState} from '../../../rootReducer';
import TableV2, {TableV2Column} from '../../GeneralComponents/Table/TableV2';
import {PaginatedResultModel} from '../../../shared/paginated-result.model';
import {useConfirm} from 'material-ui-confirm';
import './Configuration.scss';
import {DateTimeFormatter} from '../../../utils/Utils';
import SearchIcon from '@material-ui/icons/Search';
import {Avatar, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useDebounce} from 'use-debounce';
import {Link, useHistory} from 'react-router-dom';
import {MainButton} from '../Login/MainButton';
import {AdminConfigService} from "../../../service/AdminConfigService";
import {ConfigurationModel} from "./configuration.model";
import {TransactionStatus} from "../Transactions/transaction.model";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

export const Configuration: React.FC = () => {
  const [page, setPage] = useState(1);
  const [configList, setConfigList] = useState<
    PaginatedResultModel<ConfigurationModel>
  >({ results: [], resultCount: 0 });

  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const history = useHistory();
  const classes = useStyles();
  const pageSize = 10;
  const columns: TableV2Column[] = [
    {
      columnName: 'configProperty',
      columnLabel: 'Property',
      textAlign: 'left',
    },
    {
      columnName: 'configValue',
      columnLabel: 'Value',
      textAlign: 'left',
    },
    {
      columnName: 'configValue',
      columnLabel: 'Actions',
      textAlign: 'center',
      dataFormat: (value: string, config: ConfigurationModel) => {
        return (
          <ConfigActions
            value={value}
            config={config}
            updateConfig={updateConfig}
          />
        );
      },
    },
  ];

  const getConfigList = async () => {
    const data = await AdminConfigService.getConfigs(
      token,
    );
    setConfigList({ results: data, resultCount: data.length });
  };

  const updateConfig = async (property: string, value: string) => {
    var result = await AdminConfigService.updateConfig(
        token, property, value
    );
    if (result) {
      var item = configList.results.find(cfg => cfg.configProperty == property)
      if (item) {
        item.configValue = value;
      }
      setConfigList({ results: configList.results, resultCount: configList.resultCount });
    }
  };

  useEffect(() => {
    (async () => {
      await getConfigList();
    })();
  }, [page]);

  return (
    <div>
      <Header title={'Configuration'} />

      <div className="logsContainer">
        <TableV2
          columns={columns}
          data={configList}
          noDataFoundText="No Data Found!"
          currentPage={page}
          pageSize={100}
          setActivePage={setPage}
        />
      </div>
    </div>
  );
};

const ConfigActions = ({
  value,
  config, updateConfig,
}: {
  value: string;
  config: ConfigurationModel;
  updateConfig: (property: string, value: string) => void;
}) => {
  const confirm = useConfirm();
  const [open, setOpen] = React.useState(false);
  const switchOpen = () => {
    setOpen(!open);
  };
  const [editedValue, setEditedValue] = React.useState(value);
  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const confirmTransaction = () => {
    confirm({
      title: 'Confirm change',
      description: `Are you sure you want to edit the value?`,
    })
      .then(async () => {
        updateConfig(config.configProperty, editedValue);
        setOpen(false);
      })
      .catch(() => setOpen(false));
  };

  return (
    <div>
        <div className="dropdown">
          <button className="cursor-pointer" onClick={switchOpen}>
            <b>...</b>
          </button>
          {open ? (
              <ul className="menu">

                <li className="menu-item config">
                  <button className="success" onClick={confirmTransaction}>
                    Confirm
                  </button>
                  <InputText
                      id="countryName"
                      name="countryName"
                      className="input"
                      value={editedValue}
                      onChange={(e) =>
                          setEditedValue(e.target.value)
                      }
                  />

                </li>
              </ul>
          ) : null}
        </div>

    </div>
  );
};

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '../../../assets/img/icon/search.svg';
import './SearchDrawer.scss';

import { TextField } from '@material-ui/core';
import DownArrowIcon from '../../../assets/img/icon/down.svg';
import UpArrowIcon from '../../../assets/img/icon/up.svg';
import EnterIcon from '../../../assets/img/icon/enter.svg';
import IconSlash from '../../../assets/img/icon/slash.svg';
import {
  DrawerUserList,
  DrawerUserListProps,
} from './DrawerUserList/DrawerUserList';
import { UserType } from '../../Pages/Users/UserType';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#4f2c4b',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4f2c4b',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4f2c4b',
      },
      '&:hover fieldset': {
        borderColor: '#4f2c4b',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4f2c4b',
      },
    },
  },
})(TextField);

interface SearchDrawer {
  openDrawer?: () => void;
  isOpened: boolean;
}

const UserListDummyData: Array<DrawerUserListProps> = [
  {
    title: UserType.GINGRS,
    data: [
      {
        name: 'Jushawn McDowell',
        img: 'https://i.pravatar.cc/150?img=3',
      },
      {
        name: 'Anne-Marije Markinl',
        img: 'https://i.pravatar.cc/150?img=4',
      },
      {
        name: 'Abayo Stevens',
        img: 'https://i.pravatar.cc/150?img=5',
      },
    ],
  },
  {
    title: UserType.CLIENT,
    data: [
      {
        name: 'Jushawn McDowell',
        img: 'https://i.pravatar.cc/150?img=9',
      },
      {
        name: 'Anne-Marije Markinl',
        img: 'https://i.pravatar.cc/150?img=10',
      },
      {
        name: 'Abayo Stevens',
        img: 'https://i.pravatar.cc/150?img=11',
      },
    ],
  },
];

export const SearchDrawer: React.FC<SearchDrawer> = ({ isOpened }) => {
  const [state, setState] = React.useState<boolean>(isOpened);
  const [search, setSearch] = React.useState<string>('');

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState(open);
      isOpened = open;
    };

  return (
    <div>
      <SearchIcon onClick={toggleDrawer(true)} className="searchDrawerIcon" />
      <Drawer
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
        className="drawerContainer"
      >
        <div className="drawer" role="presentation">
          <CssTextField
            id="outlined-search"
            variant="outlined"
            placeholder="Search for anything"
            onChange={(event) => setSearch(event.target.value)}
            inputProps={{
              style: {
                color: '#ffffff',
                padding: 20,
              },
            }}
            fullWidth={true}
          />
          <Divider />
          <div className="iconContainer">
            <DownArrowIcon className="iconRoundedBackground" />
            <UpArrowIcon className="iconRoundedBackground" />
            <p className="searchTips">to navigate through.</p>
            <EnterIcon className="iconRoundedBackground" />
            <p className="searchTips">to select.</p>
            <IconSlash className="iconRoundedBackground" />
            <p className="searchtips">to search anytime.</p>
          </div>
          <DrawerUserList
            title={UserType.GINGRS}
            data={UserListDummyData[0].data}
            parameter={search}
          />
          <DrawerUserList
            title={UserType.CLIENT}
            data={UserListDummyData[0].data}
            parameter={search}
          />
        </div>
      </Drawer>
    </div>
  );
};

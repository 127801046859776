import React, { useState } from 'react';
import styles from './CalendarStyling.scss';
import { addDays, subDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import { SimpleButton } from '../../Users/SimpleButton/SimpleButton';

interface CalendarProps {
  onClick?: () => void;
}

export const Calendar: React.FC<CalendarProps> = () => {
  const [isCalendarOpened, openCalendar] = useState<boolean>(false);
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 0),
      key: 'selection',
    },
  ]);

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setState([selection]);
    openCalendar(!isCalendarOpened);
  };
  return (
    <div className="calendarParentContainer">
      <div>
        <SimpleButton
          text={`${new Date(state[0].startDate).toDateString()} — ${new Date(
            state[0].endDate.toDateString()
          ).toDateString()}`}
          onClick={() => openCalendar(!isCalendarOpened)}
        />
      </div>

      <div className="calendarContainer">
        {isCalendarOpened && (
          <DateRangePicker
            classNames={styles}
            onChange={handleOnChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            weekStartsOn={1}
            maxDate={new Date()}
            hide
            ranges={state}
            showNeighboringMonth={false}
            direction="horizontal"
          />
        )}
      </div>
    </div>
  );
};
